:root {
  --mobile-height: 100vh;
  --color-grey: #e5e5e5;
  --color-light-grey: #f1f1f1;
  --color-dark-grey: #555;
  --color-red: #c0272d;
  --color-step-1: #faaf3b;
  --color-step-1-secondary: #fac064;
  --color-step-2: #93b47c;
  --color-step-2-secondary: #a3c789;
  --color-step-3: #ca85a3;
  --color-step-3-secondary: #d88eae;
  overflow: auto;
  --color-light-blue: #80A7B3;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  z-index: 0;
  font-family: 'Open Sans', sans-serif;
}

*::after {
  box-sizing: border-box;
}

*::before {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: black;
}

.hidden {
  display: none;
}

.pointer {
  cursor: pointer;
}

.keeptogether {
  white-space: nowrap;
}

.blur {
  filter: blur(10px);
}

html body {
  height: 100%;
  max-width: 100%;
  width: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  height: 100%;
}

.button-primary {
  width: 200px;
  color: #EDF2F7;
  background-color: #1A202C;
  border-radius: 10px;
  text-align: center;
}

.button-secondary {
  width: fit-content;
  color: #EDF2F7;
  background-color: #1A202C;
  border-radius: 10px;
  text-align: center;
}


/* google font */
@font-face {
  font-family: Fraunces;
  src: url('./assets/fonts/Fraunces.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('./assets/fonts/OpenSans.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat.ttf');
  font-weight: 600;
}


b,
.bold {
  font-family: Open Sans Bold, sans-serif !important;
  font-weight: 600;
}


h1,
h2,
h3,
h4 {
  font-family: sans-serif;
}

input {
  outline: none;
}

.text {
  &--XL {
    font-size: 72px;
    line-height: 0.99;
    font-family: Fraunces, sans-serif;
    text-decoration: none;

    @media screen and (max-width: 800px) {
      font-size: 45px;
      line-height: 0.99;
    }

    @media screen and (max-width: 320px) {
      font-size: 45px;
      line-height: 0.99;
    }
  }


  &--L {
    text-decoration: none;
    font-style: normal;
    font-size: 42px;
    line-height: 58px;
    font-family: Fraunces, sans-serif;

    @media screen and (max-width: 800px) {
      font-style: normal;
      font-size: 30px;
      line-height: 35px;
    }

    @media screen and (max-width: 320px) {
      font-style: normal;
      font-size: 30px;
      line-height: 35px;
    }
  }

  &--M {
    text-decoration: none;
    font-size: 24px;
    line-height: 1.2;
    font-family: Fraunces, sans-serif;

    @media screen and (max-width: 1200px) {
      font-size: 20px;
      line-height: 1.2;
    }

    @media screen and (max-width: 800px) {
      font-size: 16px;
      line-height: 1.2;
    }

    @media screen and (max-width: 320px) {
      font-size: 16px;
      line-height: 1.2;
    }
  }

  &--S {
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 20px;
    line-height: 1.6;

    @media screen and (max-width: 800px) {
      font-style: normal;
      font-size: 20px;
      line-height: 1.6;
    }

    @media screen and (max-width: 320px) {
      font-style: normal;
      font-size: 18px;
      line-height: 1.6;
    }
  }

  &--XS {
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.6;

    @media screen and (max-width: 800px) {
      font-size: 14px;
      line-height: 1.6;
    }

    @media screen and (max-width: 320px) {
      font-size: 14px;
      line-height: 1.6;
    }
  }

  &--XXS {
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 1.2;

    @media screen and (max-width: 800px) {
      font-size: 12px;
      line-height: 1.2;
    }

    @media screen and (max-width: 320px) {
      font-size: 12px;
      line-height: 1.2;
    }
  }

  &--button {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }

  &--alert {
    color: #c0272d;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 1.6;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 1.6;
    }

    @media screen and (max-width: 320px) {
      font-size: 12px;
      line-height: 1.6;
    }
  }

  &--success {
    color: white;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 1.6;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 1.6;
    }

    @media screen and (max-width: 320px) {
      font-size: 12px;
      line-height: 1.6;
    }
  }


}

.bold {
  font-weight: 800;
}



/* layout */
:root {
  --primary: #1aac83;
  --error: #ff1a1a;
}

body {
  background: #f1f1f1;
  margin: 0;
  font-family: sans-serif;
  font-weight: 300;
}

header {
  background: #fff;
}

header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    width: 100%;

    .area-personale {
      p {
        display: none;
      }
    }
  }
}

header .area-personale {
  color: #333;
  text-decoration: none;
  display: flex;
  gap: 12px;
  font-family: "Open Sans Bold", sans-serif;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid var(--color-dark-grey);
  border-radius: 6px;

  a {
    text-decoration: none;
    color: black;
    font-family: "Open Sans Bold", sans-serif;
  }
}

.pages {
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
}

/* homepage */
.home {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 100px;
}

.workout-details {
  background: #fff;
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
}

.workout-details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}

.workout-details p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}

.workout-details span {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: #333;
}

/* new workout form */
label,
input {
  display: block;
}

input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

button,
.button {
  font-family: 'Montserrat', sans-serif;
  padding: 13px 24px;
  border: 0px;
  cursor: pointer;
}


div.error {
  padding: 6px;
  background: var(--error);
  border: 1px solid var(--error);
  color: white;
  border-radius: 6px;
  margin: 20px 0;
  max-width: 320px;
}

input.error {
  border: 1px solid var(--error);
}

div.success {
  padding: 6px;
  background: var(--color-step-2-secondary);
  border: 1px solid var(--color-step-2-secondary);
  color: white;
  border-radius: 6px;
  max-width: 320px;
  text-align: center;

  a {
    color: white;

  }
}



/* hero */
.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background: #e5e5e5;
  position: relative;
  height: 100%;
  padding: 40px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding: 20px;
  }
}

.hero h1 {
  text-align: right;
  z-index: 1;
  padding: 20px;

  @media screen and (max-width: 800px) {
    align-self: flex-end;
  }
}

.hero .text-button {
  max-width: 25rem;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 800px) {
    align-self: flex-end;
    max-width: none;
  }
}

.hero img {
  position: absolute;
  top: 0;
  left: 10%;
  height: 50%;
  z-index: 0;

  @media screen and (max-width: 1200px) {
    left: 0px
  }

  @media screen and (max-width: 500px) {
    left: -70px
  }
}

.color-line {
  height: 10px;
  display: flex;
  gap: 10px
}

.color-line>div {
  width: 33%;
}

.color-line>div:nth-child(1) {
  background-color: var(--color-step-1);
}

.color-line>div:nth-child(2) {
  background-color: var(--color-step-2);
}

.color-line>div:nth-child(3) {
  background-color: var(--color-step-3);
}

/* footer */
footer {
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  gap: 100px;
  background-color: black;
  color: white;
  padding: 50px 0;

  >.logo {
    width: 200px;
  }

  a {
    color: white;
  }

  @media screen and (max-width: 840px) {
    flex-direction: column;
  }
}

/* navbar */
header {
  height: 200px;
  background-color: white;
  display: flex;
  justify-content: center;
  z-index: 1;

  @media screen and (max-width: 1200px) {
    height: 120px;
  }
}

header .logo img {
  height: 85px;

  @media screen and (max-width: 1200px) {
    height: 60px;
  }
}

nav {
  display: flex;
  align-items: center;
  position: absolute;
  right: 5%;
}

nav a,
nav button {
  margin-left: 10px;
}

nav button {
  background: #fff;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}






//context

.context {
  margin: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 800px) {
    margin: 150px 0px;
  }
}

.survey-form .dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  //gap: 5%;
  gap: 25px;
  padding: 5px 50px 5px 50px;

  >* {
    width: 50%;
  }

  >.label-dropdown {
    text-align: right;
  }
}


.select-dropdown {
  position: relative;
  background-color: var(--color-light-grey);
  width: auto;
  float: left;
  max-width: 100%;
  width: 100%;
  border: 1px solid var(--color-dark-grey);
  border-radius: 5px;
  text-align: left;

  select {
    font-family: "helvetica neue", helvetica;
    font-size: 1rem;
    font-weight: 200;
    max-width: 100%;
    width: 100%;
    padding: 8px 0px 8px 10px;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &:after {
    content: " ";
    position: absolute;
    bottom: 35%;
    margin-top: -2px;
    right: 8px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--color-dark-grey);
  }

  &:before {
    content: " ";
    position: absolute;
    top: 35%;
    margin-top: -2px;
    right: 8px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--color-dark-grey);
  }
}

.multiple-forms {
  display: flex;
  flex-direction: column;
  flex: 1;

  .forms-top {
    display: flex;
    flex-direction: row;

    @media screen and (max-width:800px)  {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    gap: 0px
  }
}

.survey-form {
  width: 100%;
  height: auto;
  position: relative;
  background-color: white;

  >form {
    height: 100%;
  }
}

.survey-form fieldset:disabled {
  opacity: 0.4;
}

.survey-form fieldset {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0px;
  transition: 0.5s ease-out;
  gap: 20px;


  .fieldset-dropdowns {
    display: flex;

    flex-direction: column;



    >.multiple-texts {
      text-align: center;
    }

    >.multiple-texts p:first-of-type {
      text-align: left;
    }

    >div {
      display: flex;
      justify-content: center;
      gap: 5%;
      text-align: left;

      .short {
        width: 150px;
      }
    }
  }


  >h3 {
    text-align: center;
    margin: 50px;
  }
}



.business-elegibility {
  background-image: -webkit-linear-gradient(94deg, var(--color-step-1) 30%, var(--color-step-1-secondary) 30%);
}

.business-maturity {
  background-image: -webkit-linear-gradient(86deg, var(--color-step-2) 30%, var(--color-step-2-secondary) 30%);
}

.business-assessment {
  background-image: -webkit-linear-gradient(94deg, var(--color-step-3) 30%, var(--color-step-3-secondary) 30%);
}

@media screen and (max-width: 1400px) {
  .business-assessment {
    .multiple-texts {
      font-size: 12px;
    }

    h3 {
      font-size: 45px;
    }

    .dropdown {
      padding: 5px !important;
      flex-direction: row;


      >p {
        font-size: 12px;
      }
    }
  }
}


.grid-container {
  display: flex;
  flex-direction: column;
}

.multiple-dropdown-row {
  display: flex;
}

.multiple-dropdown-row>* {
  flex-basis: 100%;
  align-items: center;
}


.type-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__radio {
    display: flex;
    flex-direction: row;
    margin: 80px;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 20px;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      gap: 0px;
    }

    >label {
      display: flex;
      align-items: center;
      gap: 30px;
    }
  }
}


/* HIDE RADIO */
[type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type=radio]+img {
  cursor: pointer;
  opacity: 0.3;
  transition: 0.5 linear;
  width: 8vw;
  min-width: 100px;
  max-width: 200px;
}


/* CHECKED STYLES */
[type=radio]:checked+img {
  opacity: 1;
  transition: 0.5s linear;
}

.classification-of-invention {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: space-around;
}

.required-field {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.button-area {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wow-rating {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 50px;
  width: 40%;
  margin: auto;
  padding: 40px;

  .dropdown {
    display: block;

    .select-dropdown {
      width: 250px;
    }
  }
}

.rating-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 100px;
  align-items: center;
  justify-content: center;

  &__values {
    display: flex;
    gap: 50px;

    @media screen and (max-width: 800px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;

    }

    >.rating-element {
      display: flex;
      flex-direction: column;
      gap: 20px;
      text-align: center;
    }

  }

  >p {
    width: 40%;

    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
}

.rating-box {
  width: 175px;
  height: 175px;
  background: white;
  color: var(--color-light-blue);
  border: 3px solid var(--color-light-blue);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s ease-in;
}

// personal area
.admin {
  background-color: var(--color-light-grey);
}

.container-admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  width: 100%;
  margin: 20px 40px;

  .logo-admin {
    img {
      height: 86px;
    }
  }

  nav {
    position: unset;
  }

  @media screen and (max-width: 800px) {

    .logo-admin {
      img {
        height: 42px;
      }
    }
  }
}

.logout {
  gap: 12px;

  button {
    width: 120px;
    height: 40px;
    border: 1px solid var(--color-dark-grey);
    color: var(--color-dark-grey);
    border-radius: 6px;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;

    span {
      font-family: "Open-Sans-Bold", sans-serif;
    }
  }

  @media screen and (max-width: 800px) {
    button {
      width: auto;
    }

    span {
      display: none
    }

    p {
      display: none;
    }
  }
}


.admin-panel {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.admin-controls {
  display: flex;
  flex-direction: column;
  width: auto;
  min-width: 280px;
  margin: 24px;
  max-width: 300px;

  @media screen and (max-width: 1200px) {
    display: none;
  }

  .control {
    padding: 16px 48px;
    display: flex;
    gap: 10px;
    justify-content: center;
    border-radius: 6px;
    align-items: center;

    >svg {
      >path {
        fill: black;
      }
    }
  }

  .selected {
    background-color: #393939;
    color: white;

    svg {
      path {
        fill: white;
      }
    }
  }
}


.survey-area {
  display: flex;
  gap: 24px;
  width: 100%;
  background-color: white;
  margin: 24px;
  padding: 72px 48px;

  @media screen and (max-width: 800px) {
    padding: 46px 16px;
  }
}

.search-input {
  margin: 0;
}

.survey {
  display: flex;
  flex-direction: column;
  gap: 24px;

  a {
    text-decoration: none;
  }

  p {
    display: flex;
    gap: 12px;
  }

  .survey-element {
    width: 350px;
    border-radius: 10px;
    padding: 28px 16px;
    background-color: white;
    color: black;
    text-decoration: none;
    border: 1px solid #8A8A8A;

    @media screen and (max-width: 1200px) {
      width: 250px;
      padding: 16px 16px;
    }

    input {
      width: 80%;
      margin: 0 0 5px 0;
    }

    input:focus {
      outline: black;
    }

    .title-icons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .svg-circle {
        width: 24px;
        height: 24px;
        background-color: black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      svg {
        path {
          fill: white;
        }
      }
    }
  }

  .selected {
    background-color: #393939;
    color: white;

    svg {
      path {
        fill: black !important;
      }
    }

    .svg-circle {
      background-color: white !important;
    }
  }


}

.survey-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__scores {
    background-color: #393939;
    color: white;
    border-radius: 10px;

    .survey-title_date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 21px 31px;
      margin: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.36);

      @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-start;
      }

      p {
        display: flex;
        gap: 12px;
      }
    }

    .survey-scores {
      display: flex;
      justify-content: space-evenly;
      margin: 24px 0px;
      min-height: 200px;


      >.rating-element {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;
        width: 30%;
        max-width: 150px;

        .rating-box {
          width: 100%;
          height: 150px;
          max-width: 150px;
        }

        @media screen and (max-width: 1200px) {
          .rating-box {
            width: 120px;
            height: 120px;
          }
        }

        @media screen and (max-width: 800px) {
          .rating-box {
            width: 80px;
            height: 80px;
          }
        }
      }

      @media screen and (max-width: 800px) {
        min-height: 120px;
      }
    }
  }

  &__values {
    background-color: #D9D9D9;
    border-radius: 10px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .survey-details {
      display: flex;
      align-items: center;
      padding: 40px;
      gap: 40px;


      .values-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        width: 100%;
        gap: 10px;

        p {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          gap: 20px;
          padding: 0% 10%;

          >* {
            width: 50%;
          }

          .value-list__key {
            text-align: right;
          }

          .value-list__rating {
            text-align: left;
          }

          .value-list__value {
            text-align: left;
            padding: 5px 10px;
            background-color: white;
            border-radius: 10px;
            border: 1px solid var(--color-dark-grey)
          }
        }
      }
    }

    @media screen and (max-width: 800px) {
      padding: 10px 10px;
      text-align: center;

      .survey-details {
        padding: 40px 10px;
      }

      .value-list__key,
      .value-list__value,
      .value-list__rating {
        font-size: 14px;
      }
    }

  }
}

@media screen and (max-width: 1200px) {
  .business-assessment {
    padding: 40px 10px !important;
    justify-content: center;
    text-align: center;

    .values-list {
      p {
        padding: 0 !important;
      }

      .value_list__key,
      .value-list__rating,
      .value-list__rating {
        font-size: 16px
      }
    }
  }
}

@media screen and (max-width: 800px) {

  .survey-area {
    margin: 0;
  }

  .survey {
    width: 100%;

    .survey-element {
      width: 100%;
    }
  }

  .survey-details__values {
    height: 70vh;
    overflow: scroll;
  }

  .business-assessment {
    .values-list p {
      gap: 8px !important;
    }
  }

  #survey-detail {
    display: none;
  }
}




// login and signup forms
.registration-form {
  display: flex;
  height: 100vh;

  @media screen and (max-width: 800px) {
    flex-direction: column;

    >* {
      width: 100% !important;
    }
  }

  a {
    color: black;
  }

  >* {
    width: 50%
  }

  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 50px;
    position: relative;

    @media screen and (max-width: 800px) {
      padding: 200px 0px;
    }

    .form-title {
      display: flex;
      gap: 10px;
      flex-direction: column;
    }
  }

  p {
    display: flex;
    gap: 12px;
  }

  .brand-sidebar {
    display: flex;
    flex-direction: column;
    gap: 100px;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: relative;
    text-align: right;

    @media screen and (max-width: 800px) {
      align-items: flex-end;
      padding: 50px;

      >img {
        width: 40vw !important;
      }
    }

    >img {
      position: absolute;
      top: 0;
      left: 0;
      width: 20vw;
    }

    .brand-dots {
      display: flex;
      gap: 24px;

      @media screen and (max-width: 800px) {
        align-self: center;
      }

      >div:nth-child(1) {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background-color: var(--color-step-1);
      }

      >div:nth-child(2) {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background-color: var(--color-step-2);
      }

      >div:nth-child(3) {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background-color: var(--color-step-3);
      }
    }
  }
}

.user-form {
  input {
    min-width: 320px;

  }

  button {
    width: 320px;
    color: white;
    background-color: black;
  }

}

.go-back {

  &__top {
    display: flex;
    gap: 12px;
    position: absolute;
    top: 50px;
    left: 50px;
  }

  &__bottom {
    display: flex;
    gap: 12px;
    position: fixed;
    bottom: 50px;
    left: 50px;
  }
}


//popup form
.popup-content {
  width: auto !important;
  background-color: var(--color-light-grey) !important;
}

.modal {
  padding: 40px;
}

#login,
#signup {
  height: auto;

  >* {
    width: 100%
  }

  ;
}

#results {
  transition: 0.5s ease-out;
}